import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiEndPoints, AccountsApiEndPoints } from './../../app.config';
import { HttpService } from './../../shared/http/http.service';

@Injectable()
export class UserService {
  private url: string = AccountsApiEndPoints.apiEndpoint;
  private app_url: string = ApiEndPoints.apiEndpoint;
  private baseurl: string = environment.apiURL

  constructor(private http: HttpService) { }

  login(email: any, password: any) {
    return this.http.post(this.url + 'signin/', { email: email, password: password })
      .map(this.extractData)
      .catch(this.handleError);
  }

  logout() {
    return this.http.put(this.url + 'signout/', {});
  }

  changePassword(old_password: any, new_password: any) {
    return this.http.put(this.url + 'changepassword/', { old_password: old_password, new_password: new_password })
      .map(this.extractData)
      .catch(this.handleError);
  }

  forgotPassword(email: any) {
    return this.http.post(this.url + 'forgotpassword/', { email: email })
      .map(this.extractData)
      .catch(this.handleError);
  }

  resetPassword(password: any, confirm_password: any, token: any) {
    return this.http.put(this.url + 'resetpassword/', { password: password, confirm_password: confirm_password, token: token })
      .map(this.extractData)
      .catch(this.handleError);
  }

  verifyEmail(token: any) {
    return this.http.put(this.url + 'verifyemail/', { token: token })
      .map(this.extractData)
      .catch(this.handleError);
  }

  selfBillintStatus(business) {
    return this.http.get(this.app_url + 'update-self-billing-status/' + business + '/')
      .map(this.extractData)
      .catch(this.handleError);
  }

  notificationList() {
    return this.http.get(this.app_url + 'admin-notification/')
      .map(this.extractData)
      .catch(this.handleError);
  }

  notificationBadgeCount() {
    const user = JSON.parse(localStorage.getItem('user'));
    return this.http.get(this.app_url + 'admin-notification-badge/' + user.id + '/')
      .map(this.extractData)
      .catch(this.handleError);
  }
  // Extract/Handle Data from response
  private extractData(res: Response) {
    if (res.ok && res.status === 200) {
      const body = res.json();
      return body || {};
    } else {
      return Observable.throw('Error in api\'s (server)');
    }
  }

  // Handle Errors
  private handleError(error: any) {
    return throwError(error);
  }
}
