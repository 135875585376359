import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from './../user/services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers: [UserService]
})
export class HeaderComponent implements OnInit {
  @Input() sideNavVisible = false;
  @Output() showSideNav: EventEmitter<any> = new EventEmitter();

  notifiCount = 0;
  notifiList = [];
  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit() {
    // this.notificationBadgeCount()
  }

  logout() {
    this.userService.logout()
      .subscribe(
        response => {
          localStorage.removeItem('user');
          localStorage.removeItem('is_remember');
          sessionStorage.removeItem('user');
          this.router.navigate(['users/login']);
        },
        error => { }
      );
  }

  showNotification() {
    this.notificationList()
  }

  notificationList() {
    this.userService.notificationList()
      .subscribe(
        data => {
          if (data.response) {
            this.notifiList = data.response.results;
          }
        },
        error => { }
      );
  }

  notificationBadgeCount() {
    this.userService.notificationBadgeCount()
      .subscribe(
        data => {
          this.notifiCount = data.response.badge_count;
        },
        error => { }
      );
  }

  collapseMenu() { 
    this.sideNavVisible = !this.sideNavVisible;
    this.showSideNav.emit(this.sideNavVisible);
  }







}
