import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { environment } from '../environments/environment';

const _api_end_point_root = environment.apiURL;
const _api_version = environment.apiVersion;

export interface AppUrlConfig {
    apiEndpoint: string;
}

export const AuthApiEndPoints: AppUrlConfig = {
    apiEndpoint: _api_end_point_root + 'api/' + _api_version + '/o/'
};

export const ApiEndPoints: AppUrlConfig = {
    apiEndpoint: _api_end_point_root + 'web/' + _api_version + '/app/'
};

export const AccountsApiEndPoints: AppUrlConfig = {
    apiEndpoint: _api_end_point_root + 'web/' + _api_version + '/accounts/'
};

export const RetailerApiEndPoints: AppUrlConfig = {
    apiEndpoint: _api_end_point_root + 'api/' + _api_version + '/accounts/'
};

export const PhpRetailersEndPoints: AppUrlConfig = {
    apiEndpoint: _api_end_point_root + 'web/' + _api_version + '/phpretailers/'
};

export const AddressLookupApiEndPoint: AppUrlConfig = {
    apiEndpoint: 'https://api.addressy.com/Capture/Interactive/'
};


@Injectable()
export class AppConfig {
    private config: Object = null;
    private env: Object = null;

    constructor(private http: Http) { }

    public getConfig(key: any) {
        return this.config[key];
    }

    public getEnv(key: any) {
        return this.env[key];
    }
}
