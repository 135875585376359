import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PublicComponent, PUBLIC_ROUTES } from './layout/public';
import { PrivateComponent, PRIVATE_ROUTES } from './layout/private';
import { AuthGuard } from './shared/guards/auth.guard';
import { PublicGuard } from './shared/guards/public.gaurd';
import { NotFoundComponent } from './not-found/not-found.component';


const routes: Routes = [
  { path: 'not-found', pathMatch: 'full', component: NotFoundComponent, data: { breadcrumb: 'Not Found' } },
  // { path: '', pathMatch: 'full', redirectTo: '/campaigns', },
  { path: '', pathMatch: 'full', redirectTo: '/retailers/retailer', },
  { path: '', component: PublicComponent, canActivate: [PublicGuard], data: { title: 'Public Views' }, children: PUBLIC_ROUTES },
  { path: '', component: PrivateComponent, canActivate: [AuthGuard], data: { title: 'Secure Views' }, children: PRIVATE_ROUTES },
  { path: '**', redirectTo: '/not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
