import { Injectable } from '@angular/core'
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'

@Injectable()

export class PublicGuard implements CanActivate {

    constructor(private router: Router) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // if (localStorage.getItem('user') && localStorage.getItem('user') !== 'undefined') {
        //     this.router.navigate(['campaigns']);
        //     return false;
        // }
        return true;
    }
}
