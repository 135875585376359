import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';


@Component({
    selector: 'app-footer',
    template: `<div class="col-12 text-center copyright">© Copyright {{currrent_date}} World of Sweets. All rights reserved</div>`,
    styles: [`
    .copyright{
        font-size: 13px;
    font-weight: 400;
    color: #6E6E6E;
    padding-bottom: 30px;
    padding-top: 30px;
    width: 100%;
    float: left;
    }
    `]
})

export class AppFooterComponent implements OnInit {

    currrent_date = new Date().getFullYear()
    constructor() { }
    ngOnInit(): void {
    }

}