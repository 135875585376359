// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// WOs-Local
export const environment = {
  version: '1.1.1',
  production: false,
  environment: 'staging',
  apiURL: 'https://api-wos-stg.agilecollab.com/',
  apiVersion: 'v3',
  username: 'appuser@mailinator.com',
  password: 'Admin@123456',
  client_id: 'Qp3IFurNh9hKL9WcwSPTpDaKce1p6bwXrFGvFpjG',
  profile_s3_path: 'https://hubpix-platform.s3.eu-west-2.amazonaws.com',
  s3_path: 'https://d2fp78ar86jimk.cloudfront.net',
  product_image_folder:'thumbnails',
  client_secret: 'bZ3s1CEFYweEaLXiy9CIU7nFYkRwodeLfQ2o5JwqD4DeeL4nwRnbcKahwJhHW4aRGdaiVrHhmbOnrcV2iWPgrwbOA1oamAjlQZ6DFHnVsJRedLR4vkFh9BNckCkQVFeD',
  grant_type: 'password',
  address_lookup_key: 'YU99-UT62-RZ43-JH12',
  dropdown_limit: 100,
  magentoApiUrl:'https://api-wos-stg.agilecollab.com/',
  magentoApiVersion:'V1',
  page_size: 10,
  yourAccessKeyId:'AKIA6EX5YVTKFTHGT6R3',
  yourSecretAccessKey:'lKV+DsyCh0r8NjC93C5KXrbCwpso0F6QMyQPm1Ga',
  yourRegion:'eu-west-2',
  yourBucketName:'shb-ordering',
  profileFolder:'ordering/profile-images/',
  product_listing_page_size: 15,
  cart_page_size: 20,
  cmsNavigationUrl:'https://wos-retailer.agilecollab.com/',
  graphcmsapiurl:'https://api-eu-central-1.graphcms.com/v2/cknrki1wfbcpo01wj1hxy2dmz/stagenvironment',
  qraphapiToken:'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE2MTk2OTExNDAsImF1ZCI6WyJodHRwczovL2FwaS1ldS1jZW50cmFsLTEuZ3JhcGhjbXMuY29tL3YyL2NrbnJraTF3ZmJjcG8wMXdqMWh4eTJkbXovc3RhZ2Vudmlyb25tZW50IiwiaHR0cHM6Ly9tYW5hZ2VtZW50LW5leHQuZ3JhcGhjbXMuY29tIl0sImlzcyI6Imh0dHBzOi8vbWFuYWdlbWVudC5ncmFwaGNtcy5jb20vIiwic3ViIjoiNDc3MjQ2NjctMGRiMC00ZmY0LTg0MTgtMzMwNzc4MTE0NzkxIiwianRpIjoiY2tvMnFhYW5mejZ3djAxejEzeGhnYXBlaSJ9.KpjmM-f3feMhNZc2Mi40gpiO-wK4Po68cbNpJLn9oHmUmMacnK7BMjwNOeT79F4naQxkjveJ03JNLueKD8XAFC72WkRCMc4yd9HZbiA2XkAEsCPmuDdnoOGLNqnTZUVRM6qwizuJ3AuhWubXSwJhiWXVApkSsND0WXp6lfVvm8p_xjnDMst2PGcVFW7j8EPCR_bJpCtzwsTTr2wIUDrH35FhonMnjvhfTMbCUqYy3C16CAFH3xmYkjZ3Xso4Ag-2sLYh9-I_FX0YNmKgQofMhy1qYoPSVozzymYMaDC8b5YfGNqQ2vzeYwWwnamMW71rIfoYdAtQ61en8w82_mm5svqJUZdhZmo5So6bnJghg7x_FWN_s4HNPanlpATaipKE0wI_bkRIRNllVczwNIO43WUWjTA_2QUOx3_FTQIYbeQZHPcdjV44x9q9gVvr3rBsf6MBqK5XmckT35311Hrw5Do0UuzprqTTulkURNQQXbXjiPjoAGrsz1YUgs_gHVZGd7vqr0LebX77Tzvvlz_TgHmoDnu_nLZuBjwiwKO_ruMvPY_Bf-K85HNzFQKpeOZ-ldkdsEjp1g0eAaVcXflbHdKliT6O11co8BZsHwSuie0SLpz9iKIclMQHslug8qAmvtWJ8P-Z5MImolh7b2K2xtGv3eW9r6_8Y6fmoNBj9a4',
  domainName:'web-shb-bonds-stg.agilecollab.com',
  ga:'G-RDJ9TFXYHS',
  checkoutToken:"pk_test_9f73a064-520c-4eb1-958b-f953d9c7ef3c",
  wos_secret_key:'password@123',
};
