import { BaseRequestOptions } from '@angular/http';


export class AngularBearerRequestOptions extends BaseRequestOptions {

    public token: string;

    constructor(angularReduxOptions?: any) {
        super();
        try {
            const user = localStorage.getItem('user');
            const user_access_token = localStorage.getItem('user_access_token');

            if (user && user !== 'undefined') {
                const user_obj = JSON.parse(user);
                if (user_obj.access_token){
                    this.token = user_obj && user_obj.access_token;
                }
                else{
                    this.token = user_obj && user_obj.tokens.access_token;
                }
                this.headers.append('Content-Type', 'application/json');
                this.headers.append('Authorization', 'Bearer ' + this.token);
            } else if (user_access_token) {
                this.token = user_access_token;
                this.headers.append('Content-Type', 'application/json');
                this.headers.append('Authorization', 'Bearer ' + this.token);
            }
        } catch (e) {
            window.location.href = 'users/login';
        }

        if (angularReduxOptions != null) {
            for (const option in angularReduxOptions) {
                if (option) {
                    const optionValue = angularReduxOptions[option];
                    this[option] = optionValue;
                }
            }
        }
    }
}
