import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  @Output() showSideNav: EventEmitter<any> = new EventEmitter();

  constructor(private router: Router, ) { }

  ngOnInit() {
    this.nav();
  }

  nav() {
    this.showSideNav.emit(false);
  }

  isAccess(permission) {
    const params = permission.split(".");
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      const permissions = user.permissions;
      // for (let i = 0; i < permissions.length; i++) {
      //   if(params[0] == 'retailer' || params[0] == 'retailersignup'){
      //     return true
      //   }
        
      //   if (permissions[i][params[0]]) {
      //     if (permissions[i][params[0]][params[1]]) {
      //       return permissions[i][params[0]][params[1]];
      //     } else if (permissions[i][params[0]][params[2]]) {
      //       return permissions[i][params[0]][params[2]];
      //     }
      //   }
      // }
      return true
    }
  }

  navToRetailer() {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.is_superuser) {
      this.router.navigate([`/retailers`]);
    } else {
      this.router.navigate([`/retailers/retailer-details/${user.id}/details`]);
    }
  }
  
}
