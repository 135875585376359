import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()

export class AuthGuard implements CanActivate {

    constructor(private router: Router) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentuser = JSON.parse(localStorage.getItem('user'))
        if (localStorage.getItem('user') && localStorage.getItem('user') !== 'undefined' && currentuser.is_temporary_password && state.url.includes('/users/reset-password')) {
            return true;
        }
        else if (localStorage.getItem('user') && localStorage.getItem('user') !== 'undefined' && !currentuser.is_temporary_password && state.url.includes('/users/reset-password')) {
            this.router.navigate(['retailers/retailer']);
            return false;
        }
        else if (localStorage.getItem('user') && localStorage.getItem('user') !== 'undefined' && !currentuser.is_temporary_password) {
            return true;
        }
        else if (localStorage.getItem('user') && localStorage.getItem('user') !== 'undefined' && currentuser.is_temporary_password) {
            this.router.navigate(['/users/reset-password']);
            return false;
        } else {
            this.router.navigate(['users/login']);
            return false;
        }
    }
}
